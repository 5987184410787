import { Icon } from '@HometreeEngineering/component-library';
import { Appliance } from 'api/contract/types';
import { OpenArrow } from 'assets/pandoraIcons';
import { mapIconsToAppliances } from 'features/contract/utils/mapIconsToAppliances';
import { useCallback, useEffect, useState } from 'react';
import { formatPrice } from 'utils/formatPrice';
import styles from './index.module.scss';

const Appliances = ({ appliances }: { appliances: Appliance[] }) => {
    const [appliancesList, setAppliancesList] = useState<
        Array<{ type: string; data: Appliance[] }>
    >([]);
    const [isOpen, setIsOpen] = useState(false);

    const groupAppliances = useCallback(() => {
        const types: Array<{ type: string; data: Appliance[] }> = [];
        appliances.forEach((a) => {
            if (!types.length || !types.find((t) => t.type === a.type)) {
                types.push({ type: a.type as string, data: [a] });
            } else {
                const indexOfType = types.findIndex((type) => type.type === a.type);
                types[indexOfType] = {
                    type: types[indexOfType].type,
                    data: [...types[indexOfType].data, a],
                };
            }
        });
        return types;
    }, [appliances]);

    const handleOnClick = () => {
        const grouped = groupAppliances();
        if (!isOpen) {
            setAppliancesList(grouped);
        } else {
            setAppliancesList(grouped.slice(0, 5));
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const grouped = groupAppliances();
        if (appliances.length > 5) {
            setAppliancesList(grouped.slice(0, 5));
        } else {
            setAppliancesList(grouped);
        }
    }, [appliances, groupAppliances]);

    return (
        <div className={styles.container}>
            <p>
                Appliances covered (<strong>{appliances.length}</strong>){' '}
            </p>
            <div className={styles.iconsContainer}>
                {appliancesList.map(
                    (appliance) =>
                        appliance.type && (
                            <div className={styles.iconsList} key={appliance.type}>
                                <Icon icon={mapIconsToAppliances(appliance.type)} />
                                <div className={styles.appliancesDescription}>
                                    <h5 className={styles.applianceType}>
                                        {appliance.type}
                                        {appliance.data.length > 1
                                            ? ` (${appliance.data.length})`
                                            : null}
                                    </h5>
                                    {appliance.data.map((ap, index) => (
                                        <p key={ap.id} className={styles.appliancePricing}>
                                            {appliance.data.length > 1 && index === 0 ? (
                                                <>
                                                    {formatPrice(
                                                        appliance.data.reduce((total, a) => {
                                                            return (
                                                                total + (a.purchase_price_gbx ?? 0)
                                                            );
                                                        }, 0)
                                                    )}
                                                    <span className={styles.appliancePrice}>
                                                        {' '}
                                                        / total
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {formatPrice(ap.purchase_price_gbx)}
                                                    <span className={styles.appliancePrice}>
                                                        {' '}
                                                        {appliance.data.length > 1
                                                            ? '/ each'
                                                            : '/ month'}
                                                    </span>
                                                </>
                                            )}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )
                )}
            </div>
            <div className={styles.additionalIcons}>
                {isOpen ? (
                    <OpenArrow onClick={handleOnClick} aria-label="open-appliances-arrow" />
                ) : (
                    <OpenArrow
                        className={styles.rotated}
                        onClick={handleOnClick}
                        aria-label="closed-appliances-arrow"
                    />
                )}
                <p>{isOpen ? 'See less' : 'See more'}</p>
            </div>
        </div>
    );
};

export default Appliances;
